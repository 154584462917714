.body h1, .body h2, .body h3, .body h4 {
    color: #3C4858;
    font-weight: 700;
}

.body {
    font-size: 1.25em;
    color: #999;
}
.body p {
    font-size: 1em;
}

.preview:before {
    content:'';
    width:100%;
    height:100%;    
    position: absolute;
    left:0;
    top:0;
    background:linear-gradient(transparent 300px, white);
}